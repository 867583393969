import { useRef } from "react";
import { BasisTheoryProvider, useBasisTheory } from "@basis-theory/basis-theory-react";
import { type TextElement } from "@basis-theory/basis-theory-react/types";
import { Field, Form, Formik, type FormikProps } from "formik";
import { Alert, Box, Divider, Typography } from "@hotelengine/atlas-web";

import config from "config";
import { PrivacyPolicyDisclaimer } from "@hotel-engine/app/PrivacyPolicyDisclaimer";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import CheckboxField from "@hotel-engine/common/FormikFields/CheckboxField";
import Button from "@hotel-engine/common/Button";
import { CreditCardInput } from "./components/CreditCardInput";
import { BasisTheoryInput } from "./components/BasisTheoryInput";
import { ExpDateInput } from "./components/ExpDateInput";
import { wexFormSchema, type WexFormValues } from "./schema";
import * as Styled from "./styles";

export type WexFormProps = {
  onSubmitSuccess: (paymendId: string) => void;
  onCancel: () => void;
};

export const WexForm = ({ onSubmitSuccess, onCancel }: WexFormProps) => {
  const { bt } = useBasisTheory(config.basisTheoryKey, { elements: true });

  const formRef = useRef<FormikProps<WexFormValues>>(null);
  const cardNumberRef = useRef<TextElement>(null);
  const driverLicenseRef = useRef<TextElement>(null);

  const handleSubmit = async (_values: WexFormValues) => {
    const cardNumber = cardNumberRef.current;
    if (cardNumber && cardNumber.metadata.empty) {
      formRef.current?.setFieldError("cardNumber", "Card number is required");
      cardNumber.focus();
      return;
    }

    // TODO: Handle Basis Theory API call here -> FINT-2411
    //const driverLicense = driverLicenseRef.current;
    //const token = await bt?.tokens.create({
    //  type: "token",
    //  data: {
    //    cardNumber,
    //    driverLicense,
    //  },
    //});

    // TODO: Handle API save/put request here -> FINT-2411
    // TODO: Handle field errors -> FINT-2411
    // TODO: send correct paymentId -> FINT-2411
    onSubmitSuccess("paymentId");
  };

  const initialValues: WexFormValues = {
    cardNumber: "",
    default: false,
  };

  return (
    <BasisTheoryProvider bt={bt}>
      <Box display="flex" flexDirection="column" gap={16}>
        <Typography as="h3" variant="heading/md">
          Add Wex Fleet Card
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={wexFormSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ errors, isSubmitting }) => (
            <Form data-private data-testid="payment-method-wex-form">
              <Box display="flex" flexDirection="column">
                <CreditCardInput ref={cardNumberRef} error={errors.cardNumber} />
                <Field component={ExpDateInput} name="expDate" />
                <Field
                  component={InputField}
                  name="nickname"
                  label="Card nickname (optional)"
                  placeholder="Rewards"
                />
              </Box>
              <Box marginTop={4} marginBottom={20}>
                <Divider variant="dotted" />
              </Box>
              <Box display="flex" flexDirection="column" gap={4} marginBottom={20}>
                <Typography as="h3" variant="heading/md">
                  Additional details
                </Typography>
                <Typography variant="body/sm-light">
                  Please provide the necessary information below where applicable. Please ensure all
                  information is up to date.
                </Typography>
              </Box>
              <Styled.AdditionalFieldsContainer>
                <Field
                  component={InputField}
                  name="driver_number"
                  label="Driver number"
                  placeholder="######"
                  maxLength={20}
                />
                <Field
                  component={InputField}
                  name="trip_number"
                  label="Trip number"
                  placeholder="######"
                  maxLength={20}
                />
                <BasisTheoryInput
                  id="driver_license_number"
                  label="Driver's license number"
                  error={errors.driver_license_number}
                  placeholder="######"
                  maxLength={20}
                  ref={driverLicenseRef}
                />
                <Field
                  component={InputField}
                  name="unit_number"
                  label="Unit number"
                  placeholder="######"
                  maxLength={20}
                />
                <Field
                  component={InputField}
                  name="odometer"
                  label="Odometer"
                  placeholder="######"
                  type="number"
                  maxLength={20}
                />
                <Field
                  component={InputField}
                  name="po_number"
                  label="PO number"
                  placeholder="######"
                  maxLength={20}
                />
                <Field
                  component={InputField}
                  name="trailer_number"
                  label="Trailer number"
                  placeholder="######"
                  maxLength={20}
                />
                <Field
                  component={InputField}
                  name="job_number"
                  label="Job number"
                  placeholder="######"
                  maxLength={20}
                />
              </Styled.AdditionalFieldsContainer>
              <Field
                component={InputField}
                name="vehicle_number"
                label="Vehicle number"
                placeholder="######"
                maxLength={20}
              />
              <Field name="default" component={CheckboxField} label="Make default card" />
              <Box display="flex" gap={8} marginTop={4} marginBottom={20}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Save Card
                </Button>
                <Button type="ghost" htmlType="button" disabled={isSubmitting} onClick={onCancel}>
                  Cancel
                </Button>
              </Box>
              <Alert
                size="sm"
                sentiment="helpful"
                title="To verify your card, you may see a temporary charge of $1 on your account. This amount will be refunded automatically."
              />
              <Box marginTop={20}>
                <PrivacyPolicyDisclaimer $top={20} />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </BasisTheoryProvider>
  );
};
