import styled from "styled-components";

import { Box, type BoxProps } from "@hotelengine/atlas-web";

export const Container = styled<React.FC<BoxProps>>(Box)`
  cursor: pointer;
  user-select: none;
  padding: ${({ theme }) => `${theme.spacing[32]} ${theme.spacing[40]}`};
  border-top: 1px dashed ${({ theme }) => theme.colors.borderMinimal};
`;

export const Icon = styled<React.FC<BoxProps>>(Box)`
  img, i {
    width: 27px;
  }
`;
