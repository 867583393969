import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type {
  IAuthStrategies,
  IAuthStrategyLookupResponse,
} from "@hotel-engine/types/authStrategy";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useAuthStrategiesLookupQuery = (
  email: string | null | undefined,
  options?: UseQueryOptions<IAuthStrategyLookupResponse[], IErrorResponse>
): IAuthStrategies => {
  const get = useApi("get");
  const encodedEmail = encodeURIComponent(email || "");
  const endpoint = `${endpoints.authStrategies}/lookup?email=${encodedEmail}`;

  const { data, error, meta, isFetching, isFetched } = useExtendedQuery(
    endpoint,
    () => get<IAuthStrategyLookupResponse[]>(endpoint),
    options
  );

  return {
    // loading info
    isFetching,
    isFetched,

    // data accessors
    all: data ?? [],
    get: (index) => data && data[index],

    // data descriptors
    hasMany: !!(data && data.length > 1),
    hasSome: !!(data && data.length >= 1),

    // error info
    error: error?.response?.data,

    // whether auth0 should be used for email/password login
    shouldUseAuth0: !!meta?.useAuth0,

    // returns exactly one auth strategy
    // undefined when the number of auth strategies != 1
    singularStrategy: data?.length === 1 ? data[0] : undefined,

    // returns exactly one auth0-powered auth strategy
    // undefined when the number of auth0 auth strategies != 1
    singularAuth0Strategy: data?.length === 1 && !!data[0].auth0Connection ? data[0] : undefined,
  };
};
