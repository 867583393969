import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import Card from "@hotel-engine/common/Card";
import { Icon } from "@hotel-engine/common/Icon";
import Tag from "@hotel-engine/common/Tag";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, ellipsisText, sizes } from "@hotel-engine/styles";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

export const ExpressBookPaymentContainer = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(24)};
  border-top: 4px solid ${({ theme }) => theme.legacy.colors.grey[50]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 100vw;
    padding: ${pxToRem(16)};
  }
`;

export const DirectBillPopover = styled.p`
  ${typographyMixin("body-medium")}
  margin: 0;
  width: 197px;
  height: 40px;
`;

export const PaymentMethodCard = styled(Card)`
  margin-bottom: 0;

  .copy-content {
    ${ellipsisText}
  }

  @media screen and (max-width: ${sizes.breakpoints.lg}) {
    .card-content-wrapper {
      button {
        margin-left: 55px;
      }
    }
  }
`;

export const AddPaymentButton = styled(Button)`
  padding: 0;
  margin-top: 20px;
`;

export const PlusIcon = styled(Icon)`
  margin-right: 8px;
`;

export const Expired = styled.p`
  ${typographyMixin("body-small")}
  color: ${colors.red[400]};
  margin: 0;
`;

export const RequiredByTravelPolicyTagContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const PaymentMethodContainer = styled.div<{
  isDirectBillRequired: boolean;
}>`
  margin-bottom: ${({ isDirectBillRequired }) => (isDirectBillRequired ? "32px" : "0px")};
`;

export const RequiredByTravelPolicyTag = styled(Tag)`
  ${typographyMixin("body-small")}
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${colors.grey[50]};
  color: ${colors.grey[700]};
  padding: 4px 8px;
`;
