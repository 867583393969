import * as Sentry from "@sentry/browser";
import { addScript } from "@hotel-engine/scripts/hooks/useScript";
import { message } from "@hotel-engine/common/Message";
import config from "config";
import { Unsafe } from "@hotel-engine/data";

/**
 * There is no @types/onetrust that we can use. Techincally the
 * OneTrust object has many functions within it, but we can only
 * define the types for the ones we actually need.
 *
 * [Documentation on OneTrust utilities](https://community.cookiepro.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?language=en_US)
 */

// Add OneTrust scripts as soon as possible
export function addOneTrustScripts() {
  // Load this as soon as possible!
  // OneTrust Cookies Consent Notice
  if (!config.useLegacyScriptLoader) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    globalThis["OptanonWrapper"] = function () {};

    const oneTrustAccountID =
      config.stage != "production"
        ? "01926895-1a09-786c-8f9c-29803e6411c7-test"
        : "01926895-1a09-786c-8f9c-29803e6411c7";

    addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", {
      "data-domain-script": oneTrustAccountID,
      async: "false",
    });
  }
}

/**
 * After the user visits the site, they'll be prompted to configure
 * their cookie preferences. Afterward, they may want to re-configure
 * those same cookie preferences, and so we need this utility to re-open
 * that OneTrust cookie modal.
 *
 * We are legally required to provide the user with an easy way to reconfigure
 * their cookie preferences. So, if this function should fail, we need to
 * report the error.
 */
export function openOneTrustCookieModal() {
  // Only run the cookie prompt when website is NOT being used as a seatmap portal
  // inside of a RN Webview
  if (globalThis.location.pathname !== "/flights/seatmap-portal") {
    // The OneTrust cookie JS code is not from an npm package. Instead it's loaded
    // asynchronously via some <Script> tags injected into the body. These basic
    // script tags will add the "OneTrust" object to window. Maybe the script hasn't
    // been loaded yet, or maybe it never will be for whatever reason.
    const oneTrustToggleModalFn = globalThis?.OneTrust?.ToggleInfoDisplay;

    if (typeof oneTrustToggleModalFn === "function") {
      oneTrustToggleModalFn();
    } else {
      // If the user clicks a button to Manage their cookie preferences, and it's
      // not going to work for some reason, then show this toast notification to the
      // user explaining that we recognize it's broken, and submit this important
      // legal bug to Sentry
      message
        .error("Unable to open cookie preferences.")
        .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      Sentry?.captureMessage?.(
        "User attempted to launch OneTrust cookie modal, but window is missing OneTrust."
      );
    }
  }
}
