import { Button, Typography } from "@hotelengine/atlas-web";
import { useAuth0 } from "@auth0/auth0-react";
import { getSubmitButtonLinkAttributes } from "@hotel-engine/utilities/auth/strategies";

import type { IAuthStrategyLookupResponse } from "@hotel-engine/types/authStrategy";

interface ISignInWithSSOButtonProps {
  selectedAuthStrategy?: IAuthStrategyLookupResponse;
  email?: string;
}

const SSOButton = ({ selectedAuthStrategy, email }: ISignInWithSSOButtonProps) => {
  const buttonText = `Sign in with ${selectedAuthStrategy?.displayName ?? "SSO"}`;

  const { loginWithRedirect } = useAuth0();

  const { href, onClick } = getSubmitButtonLinkAttributes({
    email,
    authStrategy: selectedAuthStrategy,
    loginWithRedirect,
  });

  return (
    <Button
      variant="outlined"
      disabled={selectedAuthStrategy ? false : true}
      size="lg"
      id="auth-url"
      style={{ width: "100%" }}
      asChild
    >
      <Typography variant="body/md" as="a" href={href} onClick={onClick} target="_self">
        {buttonText}
      </Typography>
    </Button>
  );
};

export default SSOButton;
