import { useEffect } from "react";

import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";

export const useToggleSalesforceChat = (shouldHide) => {
  useEffect(() => {
    if (shouldHide) {
      toggleSalesForceChat(false);
    } else {
      toggleSalesForceChat(true);
    }

    return () => {
      // Reset salesforce chat bubble state on unmount
      toggleSalesForceChat(true);
    };
  }, [shouldHide]);
};
