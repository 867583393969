import styled from "styled-components";

import { colors, sizes } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const Footer = styled.footer<{ hideBorder?: boolean }>`
  border-top: ${({ theme, hideBorder = false }) =>
    `${hideBorder ? 0 : theme.borderWidth.normal} solid ${theme.colors.borderSubtle}`};

  &.trips-page {
    position: relative;
    z-index: 5 /* disable theming (do not replicate) */;
  }

  background-color: ${({ theme }) => theme.colors.backgroundPrimary};

  ul {
    list-style: none;
  }
  li {
    a {
      padding: ${({ theme }) => `${theme.spacing[4]} 0`};
      display: block;
      line-height: 1.42857;
    }
  }
  ul,
  li {
    margin: 0px;
  }
  a {
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    &:focus,
    &:hover {
      color: ${({ theme }) => theme.colors.foregroundSecondary};
    }
  }
  .main-links {
    display: flex;
    flex-wrap: wrap;
    border-bottom: ${({ theme }) =>
      `${theme.borderWidth.normal} solid ${theme.colors.borderSubtle}`};
    padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[16]}`};
    @media screen and (min-width: ${sizes.breakpoints.lg}) {
      padding: 40px;
    }
  }

  .link-sections {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
    @media screen and (min-width: ${sizes.breakpoints.lg}) {
      flex-basis: auto;
    }
    section {
      flex: 0 0 100%;
      min-width: 142px;
      margin-bottom: ${({ theme }) => theme.spacing[24]};
      @media screen and (min-width: ${sizes.breakpoints.sm}) {
        flex-basis: 33%;
      }
      ul {
        padding-left: 0;
      }
      &.four-cols {
        @media screen and (min-width: ${sizes.breakpoints.sm}) {
          flex-basis: 25%;
        }
      }
    }
  }

  .support-section,
  .review-section {
    flex: 0 0 100%;
    @media screen and (min-width: ${sizes.breakpoints.lg}) {
      flex-basis: auto;
    }
    ul {
      background-color: ${({ theme }) => theme.colors.backgroundSecondary};
      border-radius: ${({ theme }) => theme.borderRadius.md};
      padding: ${({ theme }) => theme.spacing[16]};
    }

    button {
      border: none;
      background-color: ${({ theme }) => theme.colors.backgroundSecondary};
      margin: ${({ theme }) => `${theme.spacing[8]} 0`};
      width: 100%;
      border-radius: ${({ theme }) => theme.borderRadius.md};
      padding: ${({ theme }) => theme.spacing[16]};
      transition: 0.3s;
      ${flexbox({
        justifyContent: "center",
        alignItems: "flex-start",
        direction: "column",
      })};

      &:focus,
      &:hover {
        background-color: ${({ theme }) => theme.colors.backgroundSecondaryHover};
        cursor: pointer;
        transition: 0.3s;
      }
    }
  }

  .social {
    padding: 16px;
    @media screen and (min-width: ${sizes.breakpoints.lg}) {
      display: flex;
      padding: 16px 40px;
    }
    ul {
      padding: 0;
      li {
        display: inline-block;
        margin: 0 20px 8px 0;
        @media screen and (min-width: ${sizes.breakpoints.lg}) {
          margin: 0 16px 0 0;
        }
        a {
          padding: 0;
        }
      }
    }
    svg {
      font-size: ${({ theme }) => theme.legacy.fontSize.lg};
    }
  }
`;

export const ButtonTitle = styled.span`
  margin: 0;
  padding: 2px 0;
  font-weight: ${({ theme }) => theme.legacy.fontWeight.semiBold};
  color: ${colors.black};
`;

export const ButtonSubtitle = styled.span`
  margin: 0;
  padding: 2px 0;
  color: ${colors.black};
`;

export const SupportChatLinkContainer = styled.div`
  border: none;
  background-color: ${colors.grey[50]};
  margin: 8px 0;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  padding: 16px 4px 16px 16px;

  transition: 0.3s;
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-start",
    direction: "column",
  })};

  @media screen and (min-width: ${sizes.breakpoints.lg}) {
    width: 230px;
  }
`;
