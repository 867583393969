import config from "config";
import type { ISearchMapProperties } from "./types";

export const defaultClusteringProperties = {
  minZoom: 4,
  maxZoom: 15,
  minPoints: 2,
  radius: 80,
  extend: 512,
  nodeSize: 64,
  log: false,
  generateId: false,
};

export const defaultSearchMapOptions = {
  mapId: config.googleMapId,
  minZoom: 4,
  maxZoom: 20,
  zoom: 13,
  clickableIcons: false,
};

export const defaultSearchMapControls = {
  controlSize: 40,
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};

export const defaultSearchMapCustomControls = {
  customSearchThisAreaControl: true,
  customMapTypeControl: true,
  customZoomControl: true,
  customExpandControl: true,
};

export const initialState: ISearchMapProperties = {
  dataset: {
    showBoundingBox: false,
    showRequestedRadius: false,
    showEffectiveRadius: false,
    showDatasetCenter: false,
  },
  clustering: {
    ...defaultClusteringProperties,
  },
  options: {
    ...defaultSearchMapOptions,
  },
  controls: {
    ...defaultSearchMapControls,
  },
  customControls: {
    ...defaultSearchMapCustomControls,
  },
};
