import { Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

export type PaymentOptionProps = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export const PaymentOption = ({ icon, label, onClick }: PaymentOptionProps) => {
  return (
    <Styled.Container
      role="button"
      aria-label={label}
      display="flex"
      alignItems="center"
      gap={16}
      onClick={onClick}
    >
      <Styled.Icon display="flex">{icon}</Styled.Icon>
      <Typography as="span" variant="body/md">
        {label}
      </Typography>
    </Styled.Container>
  );
};
