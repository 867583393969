import { ampli } from "ampli";
import { HELP_CENTER, routes } from "@hotel-engine/constants";

export interface IFooterSection {
  title: string;
  links: {
    label: string;
    href: string;
    external?: boolean;
    onClick?: () => void;
  }[];
}

export const getFooterSections = ({ user, COMPANY_URL, faqURL }): IFooterSection[] => {
  return [
    {
      title: "Company",
      links: [
        { label: "About Us", href: `https://www.${COMPANY_URL}/about-us`, external: true },
        { label: "Careers", href: `https://www.${COMPANY_URL}/careers`, external: true },
        { label: "Blog", href: `https://www.${COMPANY_URL}/blog/`, external: true },
      ],
    },
    {
      title: "Flex",
      links: [
        { label: "Flex FAQ", href: "/flex-overview", external: true },
        { label: "FlexPro FAQ", href: "/flexpro-overview", external: true },
      ],
    },
    {
      title: "Support",
      links: [
        { label: "Help Center", href: HELP_CENTER, external: true },
        { label: "FAQ", href: faqURL, external: true },
        { label: "Contact Us", href: `https://www.${COMPANY_URL}/contact-us/`, external: true },
        {
          label: "Groups",
          href: routes.groups.base,
          onClick: () =>
            ampli.clickStartNewSpecialBookingRequest({ source: "footer", userId: user?.id }),
        },
        {
          label: "Terms & Conditions",
          href: `https://www.${COMPANY_URL}/terms-of-service/`,
          external: true,
        },
      ],
    },
    {
      title: "Rewards",
      links: [
        { label: "About Rewards", href: routes.rewardsEnrollment },
        { label: "Rewards FAQ", href: routes.rewardsFaq },
        { label: "Rewards Terms", href: routes.rewardsToc },
      ],
    },
  ];
};
