import { PaymentMethodCardType } from "./types";
import { PaymentOption } from "./components/PaymentOption";
import { PaymentMethodOptions } from "./constants";
import * as Styled from "./styles";

export type SelectPaymentMethodProps = {
  onSelected: (paymentMethod: PaymentMethodCardType) => void;
};

export const SelectPaymentMethod = ({ onSelected }: SelectPaymentMethodProps) => {
  return (
    <Styled.Container>
      {Object.entries(PaymentMethodOptions).map(([key, option]) => (
        <PaymentOption
          key={key}
          icon={option.icon}
          label={option.label}
          onClick={() => onSelected(key as PaymentMethodCardType)}
        />
      ))}
    </Styled.Container>
  );
};

export { PaymentMethodCardType };
