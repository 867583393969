import { type AxiosRequestConfig, type AxiosRequestHeaders, isAxiosError } from "axios";

import { stringify } from "qs";

import { getClient, setInterceptors } from "@hotel-engine/client";

const paramsSerializer = (params): string => {
  return stringify(params, { arrayFormat: "repeat" });
};

export default class Api {
  public static _axios = (signOutOnUnauthorized = true) => {
    const client = getClient(signOutOnUnauthorized);
    setInterceptors(client, signOutOnUnauthorized);
    return client;
  };

  // eslint-disable-next-line
  public static async download<T = any>(
    url: string,
    // eslint-disable-next-line
    params?: any,
    options?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this._axios()
      .get(url, {
        data: {}, // pulled from comment elsewhere regarding get requests not sending content type headers without this?
        paramsSerializer,
        params,
        ...options,
      })
      .catch((error) => {
        throw error?.response?.data;
      });
    return response.data as T;
  }

  // eslint-disable-next-line
  public static async downloadPost<T = any>(
    url: string,
    // eslint-disable-next-line
    params?: any,
    options?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this._axios()
      .post(url, null, {
        paramsSerializer,
        params,
        ...options,
      })
      .catch((error) => {
        throw error?.response?.data;
      });
    return response.data as T;
  }

  // eslint-disable-next-line
  public static async get<T = any>(
    url: string,
    // eslint-disable-next-line
    params?: any,
    headers?: AxiosRequestHeaders,
    switchUserAttempt?: boolean
  ): Promise<T> {
    const response = await this._axios(!switchUserAttempt)
      .get(url, {
        data: {}, // pulled from comment elsewhere regarding get requests not sending content type headers without this?
        paramsSerializer,
        params,
        headers: { "Content-Type": "application/json", ...headers },
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw {
            ...error,
            message: error.message,
            data: error.response?.data,
            status: error.response?.status,
            stack: error.stack,
          };
        }
        throw error;
      });
    return response.data as T;
  }

  // eslint-disable-next-line
  public static async post<T = any>(
    url: string,
    // eslint-disable-next-line
    payload: any,
    headers?: AxiosRequestHeaders,
    switchUserAttempt?: boolean,
    // eslint-disable-next-line
    params?: any
  ): Promise<T> {
    const response = await this._axios(!switchUserAttempt)
      .post(url, payload, { headers, paramsSerializer, params })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw {
            ...error,
            message: error.message,
            data: error.response?.data,
            status: error.response?.status,
            stack: error.stack,
          };
        }
        throw error;
      });
    return response.data as T;
  }

  public static async put<T>(
    url: string,
    // eslint-disable-next-line
    payload: any,
    headers?: AxiosRequestHeaders
  ): Promise<T> {
    const response = await this._axios()
      .put(url, payload, { headers, paramsSerializer })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw {
            ...error,
            message: error.message,
            data: error.response?.data,
            status: error.response?.status,
            stack: error.stack,
          };
        }
        throw error;
      });
    return response.data as T;
  }

  public static async delete<T>(
    url: string,
    // eslint-disable-next-line
    params?: any,
    headers?: AxiosRequestHeaders
  ): Promise<T> {
    const response = await this._axios()
      .delete(url, { params, headers, paramsSerializer })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw {
            ...error,
            message: error.message,
            data: error.response?.data,
            status: error.response?.status,
            stack: error.stack,
          };
        }
        throw error;
      });
    return response.data as T;
  }

  public static async fileRequest<T>(
    method: "put" | "post",
    url: string,
    // eslint-disable-next-line
    params: any
  ) {
    const file = new FormData();
    file.append("file", params);
    file.append("filename", params.name);
    file.append("filetype", params.type);

    const response = await this._axios()
      // eslint-disable-next-line no-unexpected-multiline
      [method](url, file, {
        headers: { "Content-Type": "multipart/form-data" },
        paramsSerializer,
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw {
            ...error,
            message: error.message,
            data: error.response?.data,
            status: error.response?.status,
            stack: error.stack,
          };
        }
        throw error;
      });

    return response.data as T;
  }
}
