import { Auth } from "@hotel-engine/services";
import config from "config";

import type {
  IAuthStrategyLookupResponse,
  IAuthStrategyMineResponse,
  TLoginWithRedirect,
  IAuthStrategyMutationResponse,
} from "@hotel-engine/types/authStrategy";
import type { AuthorizationParams } from "@auth0/auth0-react";

// Gets the callback URL for an Auth0 SAML connection.
const getAuth0SAMLPath = (auth0Connection: string): URL => {
  const url = new URL(`https://${config.auth0Domain}/login/callback`);
  url.searchParams.set("connection", auth0Connection);
  return url;
};

// Gets the callback URL for a SAML connection.
// Either an auth0 endpoint or a legacy nexus endpoint.
export const getSAMLCallbackUrl = (
  authStrategy: IAuthStrategyMineResponse | IAuthStrategyMutationResponse
) => {
  if (authStrategy.auth0Connection) {
    return getAuth0SAMLPath(authStrategy.auth0Connection);
  }

  return getLegacySAMLPath(authStrategy.url);
};

// Gets the href and onClick attributes to be used for "Sign in with SSO" buttons.
// onClick is defined when an auth0_connection is returned for an auth strategy.
// The onClick function returned will redirect the user to the appropriate Auth0 endpoint
// using the loginWithRedirect function from the useAuth0 hook.
// href is defined when an auth0_connection is not returned for an auth strategy.
// The URL returned is for the legacy Nexus SAML endpoints.
// (ex: /users/auth/big-long-uuid/saml)
export const getSubmitButtonLinkAttributes = ({
  email,
  authStrategy,
  loginWithRedirect,
}: {
  email?: string;
  authStrategy?: IAuthStrategyLookupResponse;
  loginWithRedirect: TLoginWithRedirect;
}): { href: string; onClick: () => void } => {
  let href, onClick;

  if (!authStrategy) {
    return { href, onClick };
  }

  if (authStrategy.auth0Connection) {
    onClick = auth0SAMLLoginWithRedirect({
      auth0Connection: authStrategy.auth0Connection,
      loginWithRedirect,
      email,
    });
  } else {
    // otherwise, link to the legacy SAML path
    href = getLegacySAMLPath(authStrategy.url);
  }

  return { href, onClick };
};

// Returns a function that can be used as onClick event handlers
// ex: onClick={auth0SAMLLoginWithRedirect(data)}
// or in onSubmit handlers:
// ex: auth0SAMLLoginWithRedirect(data )()
//                                      ^^
// This function returns a function, so onSubmit handlers need to call this function
// and then call the function it returns right away with an extra pair of parentheses.
// See: https://developer.mozilla.org/en-US/docs/Glossary/IIFE for more info.
export const auth0SAMLLoginWithRedirect = ({
  auth0Connection,
  loginWithRedirect,
  email,
  returnTo,
}: {
  auth0Connection: string;
  loginWithRedirect: TLoginWithRedirect;
  email?: string;
  returnTo?: string;
}): (() => Promise<void>) => {
  // if there is an Auth0 connection for the strategy,
  // set the onClick to log in with Auth0
  const redirectPath = encodeURIComponent(`${globalThis.location.origin}${returnTo}`);
  const redirectUri = returnTo
    ? `${globalThis.location.origin}/?return=${redirectPath}`
    : Auth.getLoginRedirectPath();

  const authorizationParams: AuthorizationParams = {
    redirect_uri: redirectUri,
    prompt: "login",
    connection: auth0Connection,
  };

  if (email) {
    authorizationParams.login_hint = email;
  }

  return async () => {
    return await loginWithRedirect({ authorizationParams });
  };
};

// Gets the endpoint for a legacy SAML auth strategy.
const getLegacySAMLPath = (path: string): URL => {
  const url = new URL(`${config.ssoHost}${path}`);
  url.searchParams.set("target", "members");
  url.searchParams.set("path", Auth.getRedirectPath());
  return url;
};
