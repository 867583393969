import styled from "styled-components";
import Drawer from "@hotel-engine/common/Drawer";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";
import Button from "@hotel-engine/common/Button";
import type { IStatus } from "./types";
import { Alert, Box, Divider, Typography } from "@hotelengine/atlas-web";

export const SupportCaseDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    min-width: 640px;
    width: 640px;
  }

  .ant-drawer-body {
    padding: 108px 25px;
  }

  .ant-drawer-header {
    border-bottom: none;
    min-height: 108px;
  }
`;

export const StatusLabel = styled.span<IStatus>`
  display: inline;
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${(props) => (props.isActive ? colors.green[600] : colors.slate[100])};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 6px 8px;
  color: ${(props) => (props.isActive ? colors.white : colors.grey[800])};
`;

export const Row = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "center",
  })};
  margin-bottom: 12px;

  ${mediaQuery(
    "xl",
    "max"
  )(`
    &:last-child {
      margin-bottom: 0;
  }
  `)}
`;

export const SupportAlertBanner = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  flex-grow: 0;
`;

export const FieldDivider = styled(Divider)`
  color: ${({ theme }) => theme.colors.accentGray};
  margin: 24px 0;
`;

export const ItineraryButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  height: 40px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StrikethroughText = styled(Typography).attrs({})`
  text-decoration: line-through;
  margin-right: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.foregroundDisabled};
`;

export const DetailsWrapper = styled(Box).attrs({})`
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;
