import type { IAchPaymentProfile } from "./achPaymentProfile";
import type { IAutopayMethod } from "./autopay";

/** Wex payment profile */
export const WexPromptsFields = [
  "driver_number",
  "trip_number",
  "driver_license_number",
  "unit_number",
  "odometer",
  "po_number",
  "job_number",
  "trailer_number",
  "vehicle_number",
] as const;

export type WexPromptsField = (typeof WexPromptsFields)[number];

/** Direct bill credit card payment profile. */

export interface IPaymentProfile {
  /** PK */
  id: number;
  billingPostalCode: string;
  /** FK for Business */
  businessId: number | null;
  duplicate?: boolean;
  default: boolean;
  expirationMonth: string;
  expirationYear: string;
  isExpired: boolean;
  /** masked credit card number */
  cardNumber: string;
  last4: string;
  /** Name associated with the credit card. */
  name: string;
  /** Displayed name for the method */
  nickname: string | null;
  spreedlyFingerprint: string;
  /** Credit Card Type */
  type: spreedly.SpreedlyCardType;
}

//  This payment profile type is coming in the response while updating
export interface INewPaymentProfile {
  billingName: string;
  billingPostalCode: string;
  businessId: string | null;
  cardExpMonth: string;
  cardExpYear: string;
  collectionName: string;
  createdAt: string;
  creditCardNum: string;
  creditCardType: spreedly.SpreedlyCardType;
  displayName: string;
  firstName: string;
  id: number;
  lastName: string;
  status: string;
  updatedAt: string;
  userId: number;
}

/** type guard for IPaymentProfile */
export function isCreditCardPaymentProfile(
  profile: IPaymentProfile | IAchPaymentProfile | IAutopayMethod | undefined
): profile is IPaymentProfile {
  return (profile as IPaymentProfile).cardNumber !== undefined;
}

/** Credit card payment profile params whitelisted for mutation this list is the same for both create & update in the BE.  */
export type IPaymentProfileParams = Partial<
  Pick<
    IPaymentProfile,
    | "billingPostalCode"
    | "businessId"
    | "expirationMonth"
    | "expirationYear"
    | "type"
    | "default"
    | "name"
    | "cardNumber"
    | "nickname"
    | "spreedlyFingerprint"
  >
>;

/** Credit card create params. */
export type IPaymentProfileCreateParams = IPaymentProfileParams;
/** Credit card update params. */
export type IPaymentProfileUpdateParams = IPaymentProfileParams & Pick<IPaymentProfile, "id">;
