import { createConsumer } from "@rails/actioncable";

import { getClient } from "@hotel-engine/client";
import type { IReservationBase, IReservationResponse } from "@hotel-engine/types/reservation";
import { captureMessage, isTripCancellable } from "@hotel-engine/utilities";
import { getAccessToken } from "@hotel-engine/utilities/auth";
import config from "config";

import { Auth } from ".";
import Api from "./Api";
import Resource from "./Resource";
import { Unsafe } from "@hotel-engine/data";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
class Reservation extends Resource {
  public static finalStatuses = ["booked", "failed", "cancelled"];

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static async updateCustomFields(
    contractNumber,
    data
  ): Promise<
    Array<{
      customFieldId: number;
      id: number;
      customFieldName: string;
      customFieldOptions: string[] | null;
      value: string;
    }>
  > {
    if (!data) {
      throw Error("No data provided");
    }

    return await Api.put(
      `${config.apiHostV2}/contracts/${contractNumber}/contract_custom_fields`,
      data
    );
  }

  public static async getDocument(contractNumber, documentName) {
    // check if user is on IE
    const isIE = Boolean(globalThis.navigator && globalThis.navigator.msSaveOrOpenBlob);
    // the following line is so safari will not block the event...
    // safari will prevent window.open if doesn't occur immediately following click
    // in this case safari disdains the api request happening before window.open
    const preFlightWindow = isIE ? null : globalThis.open();
    // get pdf string
    const res = await Api.download(
      `${config.apiHostV2}/contracts/${contractNumber}/${documentName}.pdf`,
      {},
      { responseType: "blob" }
    );

    // create new blob object from response
    const blob = new Blob([res], { type: "application/pdf" });
    if (isIE) {
      // for IE
      globalThis.navigator.msSaveOrOpenBlob &&
        globalThis.navigator.msSaveOrOpenBlob(blob, "itinerary.pdf");
    } else {
      // create object url
      const url = globalThis.URL.createObjectURL(blob);
      // open url in new recently opened window or send a message to sentry if window is null
      preFlightWindow
        ? (preFlightWindow.location.href = url)
        : captureMessage("Error opening itinerary window", {
            error: new Error("Could not open window with window.open"),
          });
    }
  }

  public approverId!: number;
  public cancelBy!: string;
  public status!: "booked" | "visiting" | "completed" | "cancelled";
  public checkOut!: string;
  public checkIn!: string;
  public confirmationNumber!: string;
  public documentName!: string;
  public action!: "download" | "open";
  public userId!: number;
  public propertyId!: string;
  public flexType?: "unassigned" | "modification" | "cancellation" | "total";
  public flexEnabled!: boolean;
  public propertyTimezone!: string;

  public async cancel(): Promise<IReservationResponse> {
    if (!isTripCancellable(this)) {
      throw new Error("Reservation is not cancellable");
    }

    const restCancel = async (): Promise<void> => {
      const cancelResponse = await getClient(false).delete(
        `${config.apiHostV2}/contracts/${this.id}`,
        {
          validateStatus: () => true,
        }
      );

      if (cancelResponse.status >= 300) {
        if (cancelResponse.status === 401) {
          // **redirect to login page and log out a user on a 401**
          await Auth.signOut("Reservation.cancel");
        }
        throw {
          statusCode: cancelResponse.status,
          errors: cancelResponse.data.errors,
        };
      }
    };

    const token = (await getAccessToken()) || "";
    const consumerUrl = new URL(config.apiHost);
    consumerUrl.pathname = "/cable";
    consumerUrl.searchParams.set("user_token", token);
    const consumer = createConsumer(String(consumerUrl));

    return new Promise((resolve, reject) => {
      consumer.subscriptions.create(
        { channel: "ContractChannel", room_id: this.id },
        {
          received(data: IReservationResponse) {
            if (!(data.initial_message && ["", "booked", "visiting"].includes(data.status))) {
              if (!data.status || data.status === "failed") {
                reject({
                  ...data,
                  errors: data.status_message ? [data.status_message] : [],
                });
                consumer.disconnect();
              }

              if (Reservation.finalStatuses.includes(data.status)) {
                // resolve promise if cancellation is successful
                resolve(data);
                consumer.disconnect();
              }
            } else {
              restCancel().then(Unsafe.DO_NOTHING, reject);
            }
          },
        }
      );
    });
  }

  public async email(options) {
    if (!options) {
      return;
    }

    return await Api.post(`${config.apiHostV2}/itinerary_email_requests`, {
      ...options,
      contractNumber: this.id,
    });
  }

  public static async getReceipt(id: IReservationBase["id"], checkIn: IReservationBase["checkIn"]) {
    const body = {
      contract_number: id,
      custom: {
        type: "Client Inquiry",
        subject: `Client Inquiry, ${id}, ${checkIn}`,
        priority: "Low",
        description: "Folio Request",
        case_sub_type__c: "Folio Request",
      },
    };

    return await Api.post(`${config.apiHostV2}/salesforce/cases`, body);
  }
}

export default Reservation;
