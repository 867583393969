import { ampli } from "ampli";

// style constants
export const navHeightDesktop = 80;
export const navHeightMobile = 56;

import {
  BILLING,
  COMPANY_SETTINGS,
  DASHBOARD,
  GROUPS,
  MEMBERS,
  TRENDS,
  TRIPS,
  SUPPORT_CENTER,
  routes,
  supportCenterRouteSources,
  ORDERS,
  TRAVEL_POLICIES,
} from "@hotel-engine/constants";
import { isUserEnrolledInRewards } from "@hotel-engine/utilities";

import type { IRole } from "@hotel-engine/types/role";
import type { IUser } from "@hotel-engine/types/user";
import type { SubMenuCards } from "@hotel-engine/app/Header/components/redesignComponents/TopNavMenu";
import type { IconProps } from "@hotelengine/atlas-web";

export const hePerkBusinessId = 260129;
export const MORE = "more";

export const userRoleMap: Record<IRole, string> = {
  admin: "Administrator",
  coordinator: "Coordinator",
  user: "Traveler",
  view_only_traveler: "View Only Traveler",
};

export const roleLinks: Record<IRole, ILinks["id"][]> = {
  admin: [
    DASHBOARD,
    TRIPS,
    MEMBERS,
    TRENDS,
    GROUPS,
    COMPANY_SETTINGS,
    BILLING,
    SUPPORT_CENTER,
    MORE,
  ],
  coordinator: [DASHBOARD, TRIPS, TRENDS, GROUPS, SUPPORT_CENTER, MORE],
  user: [DASHBOARD, TRIPS, GROUPS, SUPPORT_CENTER, MORE],
  view_only_traveler: [TRIPS, SUPPORT_CENTER, MORE],
};

type LinksValueId =
  | typeof DASHBOARD
  | typeof TRIPS
  | typeof MEMBERS
  | typeof BILLING
  | typeof COMPANY_SETTINGS
  | typeof TRENDS
  | typeof GROUPS
  | typeof SUPPORT_CENTER
  | typeof TRAVEL_POLICIES
  | typeof MORE;

type LinkValueLinkType = {
  id: LinksValueId;
  name: string;
  route: string;
  type: "link";
  subMenuItems?: SubMenuCards;
};

type LinkValueMenuTriggerType = Omit<LinkValueLinkType, "type"> & {
  type: "menu-trigger";
  subMenuItems: SubMenuCards;
};

type LinkValueDynamicType = Omit<LinkValueLinkType, "type"> & {
  type: "dynamic";
  subMenuItems?: SubMenuCards;
};

export type ILinks = LinkValueLinkType | LinkValueMenuTriggerType | LinkValueDynamicType;

export const LinkValues: ILinks[] = [
  {
    id: DASHBOARD,
    name: "Book",
    route: routes.dashboard,
    type: "link",
  },
  {
    id: TRIPS,
    name: "Trips",
    route: routes.trips.upcoming,
    type: "link",
  },
  {
    // this can be a link or menu trigger depending on user role
    id: GROUPS,
    name: "Groups",
    route: `${routes.groups.dashboard}/open`,
    type: "dynamic",
    subMenuItems: [
      {
        id: GROUPS,
        title: "Groups",
        icon: "users-rectangle",
        href: `${routes.groups.dashboard}/open`,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "groups" });
        },
      },
      {
        id: ORDERS,
        title: "Orders",
        icon: "truck-pickup",
        href: routes.orders,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "orders" });
        },
      },
    ],
  },
  {
    id: BILLING,
    name: "Billing",
    route: routes.billing,
    type: "link",
  },
  {
    id: "more",
    name: "More",
    route: "",
    type: "menu-trigger",
    subMenuItems: [
      {
        id: TRENDS,
        title: "Trends",
        icon: "wave-pulse",
        content: "Track spending and savings",
        href: routes.trends.all,
      },
      {
        id: TRAVEL_POLICIES,
        title: "Policies",
        icon: "shield-keyhole",
        content: "Customize traveler tiers",
        href: routes.companySettings.travelPolicies,
        showOnMobile: true,
      },
      {
        id: COMPANY_SETTINGS,
        title: "Settings",
        icon: "list-check",
        content: "Dial in company preferences",
        href: routes.companySettings.userPermissions,
        showOnMobile: true,
      },
      {
        id: MEMBERS,
        title: "Members",
        icon: "users-rectangle",
        content: "Manage team members",
        href: routes.members.all,
      },
      {
        id: SUPPORT_CENTER,
        title: "Support",
        href: `${routes.supportCenter}?source=${supportCenterRouteSources.menuBar}`,
        content: "Get help and check on requests",
        icon: "circle-question",
        showOnMobile: true,
      },
    ],
  },
];

interface IGetUserNavLinks {
  shouldShowReferAFriend: boolean;
  shouldShowTravelCredits: boolean;
  shouldShowRewards: boolean;
  shouldShowMyProperties: boolean;
  isInviteTravelerEnabled: boolean;
  onInviteTraveler: () => void;
  user: IUser;
}

interface IFooterDropdownItem {
  label: string;
  onClick?: (user: IUser) => void;
  icon: IconProps["name"];
  shouldShow: boolean;
  route?: string;
}

export const getUserNavLinks = ({
  shouldShowReferAFriend,
  shouldShowRewards,
  shouldShowTravelCredits,
  shouldShowMyProperties,
  onInviteTraveler,
  isInviteTravelerEnabled,
  user,
}: IGetUserNavLinks): IFooterDropdownItem[] =>
  [
    {
      icon: "he-rewards",
      label: "Rewards",
      shouldShow: shouldShowRewards,
      route: isUserEnrolledInRewards(user) ? routes.rewards : routes.rewardsEnrollment,
    },
    {
      icon: "money-bill-simple-wave",
      label: "Travel credit",
      shouldShow: shouldShowTravelCredits,
      route: routes.travelCredits,
    },
    {
      icon: "heart",
      label: "Favorites",
      shouldShow: shouldShowMyProperties,
      route: routes.myPropertiesFavorites,
    },
    {
      icon: "user-plus",
      label: "Invite traveler",
      shouldShow: isInviteTravelerEnabled,
      onClick: onInviteTraveler,
    },
    {
      icon: "gear",
      label: "Settings",
      shouldShow: true,
      route: routes.settings.profile,
    },
    {
      icon: "gift",
      label: "Refer a Friend",
      shouldShow: shouldShowReferAFriend,
      route: routes.referFriends.base,
    },
  ] as const;

interface IGetInternalBusinessNavLinks {
  showEngines: boolean;
  showRatelessProperties: boolean;
  showSearchRadiusCircle: boolean;
  showSearchMapTools: boolean;
  toggleEnginePreferences: (toggle: boolean) => void;
  toggleRatelessProperties: (toggle: boolean) => void;
  toggleSearchRadiusCircle: (toggle: boolean) => void;
  toggleSearchMapTools: (toggle: boolean) => void;
}

export const getInternalBusinessNavLinks = ({
  showEngines,
  showRatelessProperties,
  showSearchRadiusCircle,
  showSearchMapTools,
  toggleEnginePreferences,
  toggleRatelessProperties,
  toggleSearchRadiusCircle,
  toggleSearchMapTools,
}: IGetInternalBusinessNavLinks) => {
  return [
    {
      label: "Show Engines",
      isChecked: showEngines,
      onChange: () => toggleEnginePreferences(!showEngines),
    },
    {
      label: "Show rateless properties",
      isChecked: showRatelessProperties,
      onChange: () => toggleRatelessProperties(!showRatelessProperties),
    },
    {
      label: "Show search radius circle",
      isChecked: showSearchRadiusCircle,
      onChange: () => toggleSearchRadiusCircle(!showSearchRadiusCircle),
    },
    {
      label: "Show search map tools",
      isChecked: showSearchMapTools,
      onChange: () => toggleSearchMapTools(!showSearchMapTools),
    },
  ] as const;
};

export const headerBackgroundColorOverrides = {
  [routes.flexPassFAQ]: "linear-gradient(90deg, rgba(239, 251, 242), 47%, rgba(125, 219, 137) 50%)",
  [routes.rewards]: "brandSecondary",
} as const;
