import { toast } from "@hotelengine/atlas-web";

interface IOnMutateError {
  /** Optaional: Message to display in toast if error is not field validation related. */
  message?: string;
  /** Optional: Useful when the field names returned from backend do not align with how the
   *  fields are named in Formik values.  Usage is:
   *  {
   *     ["Backend Name"]: "Frontend Name"
   *  }
   *
   *  TODO: Needs to be implemented still!
   */
  nameMapper?: { [k: string]: string };
  /** Optional: Callback executed after any errors not field validation related. */
  onDefaultError?: () => void;
  /** Optional: Callback executed after invalidating fields on field validation error. */
  onFieldError?: () => void;
  /** Required: Formik setFieldError */
  setFieldError: (field: string, value: string) => void;
}

/** Helper to standardize error handling for react-query mutations */
const onMutateError = (
  e,
  {
    message = "Something went wrong.",
    // nameMapper,
    onDefaultError,
    onFieldError,
    setFieldError,
  }: IOnMutateError
) => {
  // Handle field errors for form validation
  try {
    const { fieldErrors, invalidParams } = e.response?.data ?? {};
    const apiErrors = fieldErrors || invalidParams;

    if (apiErrors) {
      apiErrors.forEach((error) => setFieldError(error.name, error.reasons.join(" ")));

      if (onFieldError) {
        onFieldError();
      }
    } else {
      // Pass flow to catch block
      throw new Error();
    }
  } catch {
    // Handle all other errors
    toast({ title: message, icon: "circle-exclamation", sentiment: "critical" });

    if (onDefaultError) {
      onDefaultError();
    }
  }
};

export default onMutateError;
