import { useAppSelector } from "store/hooks";
import { SignInModalWrapper } from "./components/SignInModalWrapper";
import { SignInWithEmailPlusSSO } from "./components/SignInWithEmailPlusSSO";

import { useAuthStrategiesLookupQuery } from "@hotel-engine/react-query/authStrategies/useAuthStrategiesQuery";

interface ISignInModalProps {
  /** Indicates the modal is visible */
  visible: boolean;
  /** Closes out the modal */
  onCancel: () => void;
}

export const SignInModal = ({ visible, onCancel }: ISignInModalProps) => {
  const user = useAppSelector((state) => state.Auth.user);

  let enabled = false;
  let lookupEmail: string | null = null;

  if (user?.accountType === "business") {
    enabled = !!user.personalTravelUserId;
    lookupEmail = user.personalTravelUserEmail;
  } else if (user?.accountType === "personal") {
    enabled = !!user.businessTravelUserId;
    lookupEmail = user.businessTravelUserEmail;
  }

  const authStrategies = useAuthStrategiesLookupQuery(lookupEmail, { enabled });

  return visible ? (
    <SignInModalWrapper visible={!!authStrategies.isFetched} onCancel={onCancel}>
      <SignInWithEmailPlusSSO
        authStrategies={authStrategies}
        lookupEmail={lookupEmail}
        user={user}
      />
    </SignInModalWrapper>
  ) : null;
};
