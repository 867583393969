import { forwardRef } from "react";
import type { TextElement as ITextElement } from "@basis-theory/basis-theory-react/types";
import { useFormikContext } from "formik";
import { Box } from "@hotelengine/atlas-web";

import { BasisTheoryInput, type BasisTheoryInputProps } from "../BasisTheoryInput";

type CreditCardInputProps = {
  error?: string;
} & Pick<BasisTheoryInputProps, "value" | "onChange" | "onBlur">;

export const CreditCardInput = forwardRef<ITextElement, CreditCardInputProps>(
  ({ error, value, ...rest }, ref) => {
    const { setFieldError } = useFormikContext();

    const onChange: BasisTheoryInputProps["onChange"] = (e) => {
      if (!e.empty) {
        setFieldError("cardNumber", undefined);
      }
    };

    return (
      <Box marginBottom={16}>
        <BasisTheoryInput
          id="cardNumber"
          label="Card Number"
          hint="Prefix number + Account number + Card number"
          error={error}
          placeholder="##### ##### #####"
          ref={ref}
          value={value}
          onChange={onChange}
          transform={[/\D/g, ""]}
          inputMode="numeric"
          disabled={!!value}
          {...rest}
        />
      </Box>
    );
  }
);

CreditCardInput.displayName = "CreditCardInput";
