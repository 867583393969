import type { ReactElement } from "react";
import { useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { Box } from "@hotelengine/atlas-web";

import NuclearErrorModal from "@hotel-engine/app/modals/NuclearErrorModal";
import { PrivacyPolicyDisclaimer } from "@hotel-engine/app/PrivacyPolicyDisclaimer";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import Button from "@hotel-engine/common/Button";
import Typography from "@hotel-engine/common/Typography";
import Image from "@hotel-engine/common/Image";
import { notification } from "@hotel-engine/common/Notifications";
import { Popover } from "@hotel-engine/common/Popover";
import { useURLParams } from "@hotel-engine/hooks";
import { usePaymentProfileQuery } from "@hotel-engine/react-query/paymentProfile/usePaymentProfileQuery";
import { useUpdateDirectBillOnboardingMethod } from "@hotel-engine/react-query/directBillOnboarding/useUpdateDirectBillOnboardingMethod";
import type { ICheckoutQueryParams, IDirectBill } from "@hotel-engine/types/booking";
import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";
import { ampli } from "ampli";
import config from "config";
import { useAppSelector } from "store/hooks";
import { getProductVertical } from "pages/Checkout/utils/ampliMetricHelpers";

import { useContractRoomData } from "../hooks/useContractRoomData";
import { usePricingCalculation } from "../hooks/usePricingCalculation";
import type { ICheckoutFormData } from "../../validation";
import { DirectBillItem, directBill, formatDirectBillBalanceMessage } from "./DirectBillItem";
import { PaymentItem } from "./PaymentItem";
import { PaymentMethodForm } from "./PaymentMethodForm";
import { SelectPaymentMethod, PaymentMethodCardType } from "./SelectPaymentMethod";
import * as Styled from "./styles";
import { PaymentMethodMode } from "./types";
import { WEX_ORG_ID } from "./constants";
import { WexForm } from "./components/WexForm";

export const isDirectBill = (
  paymentMethod?: IPaymentProfile | IDirectBill
): paymentMethod is IDirectBill => {
  return paymentMethod?.id === 0;
};

export const isPaymentMethodValid = (payment?: IPaymentProfile) => {
  return payment && !!payment.billingPostalCode && !payment.isExpired;
};

export const PaymentMethods = () => {
  const [paymentMethodMode, setPaymentMethodMode] = useState<PaymentMethodMode>(
    PaymentMethodMode.Selected
  );

  const [cardType, setCardType] = useState<PaymentMethodCardType>(PaymentMethodCardType.Card);

  const [directBillAccess, setDirectBillAccess] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<ICheckoutFormData>();
  const lastPaymentId = useAppSelector((state) => state.UserPrefs.lastPaymentId);
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const { contractRate } = useContractRoomData();
  // NOTE: Using this for disabling features as part of Morpheus MS1 and LODGE-39.  Revisit in future
  const isMorpheusContract = Boolean(contractRate?.continuationToken);
  const { customerTotal } = usePricingCalculation(currencyCode, true);

  const paymentProfileQuery = usePaymentProfileQuery({ limit: 400 });
  const isError = paymentProfileQuery.isError;

  const {
    params: { propertyId },
    search: { roomRateId, s },
  } = useURLParams<ICheckoutQueryParams>();

  const isWexFleetCardEnabled = useIsFeatureFlagOn("wex-fleet-card");
  const isWexReferral = user?.business?.organizationId === WEX_ORG_ID;

  /** Determines need to return default payment method on paymentProfileQuery load
   * Check for existence of paymentId
   * Check if paymentId is 0 (Direct Bill - should always be 0)
   * if paymentId does not exist
   * Check paymentProfileQuery results for valid payment method
   * if no payment profile getDefaultPayment
   * */
  useEffect(() => {
    const hasPaymentId = values.selectedPaymentId || values.selectedPaymentId === 0;
    if (!hasPaymentId) {
      const paymentProfile = paymentProfileQuery.data?.results.find(
        (paymentMethod) => paymentMethod.id === values.selectedPaymentId
      );

      if (!paymentProfile) {
        getDefaultPayment(paymentProfileQuery.data?.results);
      }
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProfileQuery.data?.results]);

  const paymentProfiles = paymentProfileQuery.data?.results;
  let selectedPayment: IPaymentProfile | IDirectBill | undefined;
  if (paymentProfiles) {
    selectedPayment = [...paymentProfiles, directBill].find(
      (payment) => payment.id === values.selectedPaymentId
    );
  }

  const directBillQuery = useUpdateDirectBillOnboardingMethod();
  const hasDirectBill = user?.directBill && user?.business?.directBillEnabled;
  const hasViewedCheckoutPage = user?.directBillOnboarding?.checkOutPage;

  // Shows direct bill onboarding popover
  const showDirectBillPopover =
    !hasViewedCheckoutPage && hasDirectBill && !isDirectBill(selectedPayment);

  // gets and sets the selected payment option. used on load and after any payment method updates
  const getDefaultPayment = (paymentMethods = paymentProfiles) => {
    if (!paymentMethods) return;

    const userDefaultPayment = paymentMethods?.find((p: IPaymentProfile) => p.default);

    // user chosen default payment
    if (isPaymentMethodValid(userDefaultPayment)) {
      setFieldValue("selectedPaymentId", userDefaultPayment?.id);
      return;
    }

    // last used payment method
    const lastUsedPayment = paymentMethods?.find((p: IPaymentProfile) => p.id === lastPaymentId);
    if (isPaymentMethodValid(lastUsedPayment)) {
      setFieldValue("selectedPaymentId", lastUsedPayment?.id);
      return;
    }

    const isInsufficientBalance =
      !!user?.business.availableCredit && user?.business.availableCredit < customerTotal;
    const isDirectBillDisabled =
      (contractRate?.isCancellable && user?.business.directBillVerificationRequired) ||
      isInsufficientBalance;

    // direct bill payment method
    if (hasDirectBill && !isDirectBillDisabled) {
      setFieldValue("selectedPaymentId", directBill.id);
      return;
    }

    const validPaymentProfile = paymentMethods?.find((p: IPaymentProfile) =>
      isPaymentMethodValid(p)
    );

    // check for any valid payment method as a fallback
    if (validPaymentProfile) {
      setFieldValue("selectedPaymentId", validPaymentProfile.id);
      return;
    }

    // If we have invalid payment methods, show them as options, otherwise show the add payment method form
    paymentMethods.length
      ? setPaymentMethodMode(PaymentMethodMode.Change)
      : setPaymentMethodMode(PaymentMethodMode.Edit);
    setFieldValue("selectedPaymentId", undefined);
  };

  // Travel Policy - Direct Bill Access based on config and customer total
  const isDirectBillBlocked = isMorpheusContract || user?.directBillConfiguration === "blocked";
  const isDirectBillRequired = user?.directBillConfiguration === "required";
  const isDirectBillOptional = user?.directBillConfiguration === "optional";

  useEffect(() => {
    const creditLimitAvailable =
      (!!user?.business.availableCredit && user?.business.availableCredit) || 0;
    const hasSufficientBalance = customerTotal < creditLimitAvailable;

    if (isDirectBillRequired && hasSufficientBalance) {
      setDirectBillAccess(true);
      setFieldValue("selectedPaymentId", directBill.id);
      setPaymentMethodMode(PaymentMethodMode.Selected);
    } else if (
      (isDirectBillRequired || isDirectBillOptional) &&
      !hasSufficientBalance &&
      isDirectBill(selectedPayment)
    ) {
      setDirectBillAccess(false);
      setPaymentMethodMode(PaymentMethodMode.Change);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTotal]);

  // update the selected payment method
  const handlePaymentSelect = (id: number) => {
    setFieldValue("selectedPaymentId", id);
    setPaymentMethodMode(PaymentMethodMode.Selected);
  };

  // clean up after form closes
  const handleCloseForm = (callback?: () => void) => {
    callback?.();
    getDefaultPayment();
    setPaymentMethodMode(PaymentMethodMode.Change);
  };

  // clean up after form is saved
  const handleSavedPayment = async (paymentForm: IPaymentProfile) => {
    const isExistingPayment = paymentProfiles?.find(
      (existingPayment: IPaymentProfile) => existingPayment.id === paymentForm.id
    );

    // existing card was updated
    if (selectedPayment) {
      notification.success({
        message: "Payment method saved",
      });
      // trying to add a duplicate card
    } else if (isExistingPayment) {
      notification.error({
        message: "Duplicate Payment Method",
        description: `The card ending in ${paymentForm.last4} already exists.`,
      });

      // brand new card added
    } else {
      notification.success({
        message: "Payment method saved",
      });
    }

    // Don't select existing payment if expired
    if (isExistingPayment && isExistingPayment.isExpired) {
      return;
    }

    setPaymentMethodMode(PaymentMethodMode.Selected);
    globalThis.Spreedly.removeHandlers();
    setFieldValue("selectedPaymentId", paymentForm.id);
  };

  const handleChangePayment = () => {
    ampli.clickChangePayment({
      searchId: Number(s),
      roomRateId: Number(roomRateId),
      propertyId: Number(propertyId),
    });
    setPaymentMethodMode(PaymentMethodMode.Change);
    directBillQuery.mutate({ checkOutPage: true });
  };

  const handleAddPayment = () => {
    const vertical = getProductVertical();
    ampli.paymentMethodAddAttempted({
      searchId: Number(s),
      vertical: vertical,
    });

    setPaymentMethodMode(
      isWexFleetCardEnabled && isWexReferral ? PaymentMethodMode.Add : PaymentMethodMode.Edit
    );
    setFieldValue("selectedPaymentId", undefined);
  };

  const handleAddPaymentMethodCardType = (value: PaymentMethodCardType) => {
    setCardType(value);
    setPaymentMethodMode(PaymentMethodMode.Edit);
  };

  let title = selectedPayment?.name ?? "";
  let label = "";
  let description = "";
  let subDescription: ReactElement | undefined = undefined;

  if (selectedPayment) {
    if (isDirectBill(selectedPayment)) {
      description = formatDirectBillBalanceMessage(user);
    } else {
      title = selectedPayment.nickname || selectedPayment.name;
      label = selectedPayment.default ? "Default" : "";

      description = `ending in ${
        selectedPayment.last4 ||
        // We need to account for credit card last four digits after EDITING is complete
        selectedPayment.cardNumber.replaceAll("-", "")
      }`;

      subDescription = selectedPayment?.isExpired ? (
        <Styled.Expired id="expired-payment-method">(Expired)</Styled.Expired>
      ) : undefined;
    }
  }

  if (isError) {
    return <NuclearErrorModal hasPageError />;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap={24}>
      <Typography as="h2" variant="large">
        Payment Method
      </Typography>

      {paymentMethodMode === PaymentMethodMode.Selected && (
        <Box display="flex" flexDirection="column" alignItems="stretch" gap={16}>
          <Styled.PaymentMethodContainer isDirectBillRequired={directBillAccess}>
            {!!selectedPayment && (
              <Popover
                align={{
                  offset: ["-5%", "0%"],
                }}
                content={
                  <Styled.DirectBillPopover>
                    Remember to add Direct Bill as your payment method
                  </Styled.DirectBillPopover>
                }
                data-testid="direct-bill-popover"
                key="direct-bill-popover"
                placement="topRight"
                visible={showDirectBillPopover}
              >
                <Styled.PaymentMethodCard
                  title={title}
                  label={label}
                  description={description}
                  subDescription={subDescription}
                  singleAction={
                    isDirectBillRequired && isDirectBill(selectedPayment) ? (
                      <Styled.RequiredByTravelPolicyTagContainer>
                        <Styled.RequiredByTravelPolicyTag>
                          Required by travel policy
                        </Styled.RequiredByTravelPolicyTag>
                      </Styled.RequiredByTravelPolicyTagContainer>
                    ) : (
                      <Button type="link" onClick={handleChangePayment}>
                        Change Payment
                      </Button>
                    )
                  }
                  img={
                    <Image
                      src={`${config.cdnHost}/assets/creditcards/${selectedPayment.type
                        .split(" ")
                        .join("")}.png`}
                      alt={`${selectedPayment.type}`}
                      fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                      width={40}
                      height={24}
                      setContainerSize={false}
                    />
                  }
                />
              </Popover>
            )}
          </Styled.PaymentMethodContainer>
          <>
            {(!directBillAccess || !!isDirectBillOptional) && (
              <Styled.AddPaymentButton type="link" onClick={handleAddPayment}>
                <Styled.PlusIcon icon={["fal", "plus"]} />
                Add Payment Method
              </Styled.AddPaymentButton>
            )}
          </>
        </Box>
      )}

      {paymentMethodMode === PaymentMethodMode.Add && (
        <SelectPaymentMethod onSelected={handleAddPaymentMethodCardType} />
      )}

      {paymentMethodMode === PaymentMethodMode.Edit && (
        <>
          {cardType === PaymentMethodCardType.Wex && (
            <WexForm
              onSubmitSuccess={() => {
                setPaymentMethodMode(PaymentMethodMode.Selected);
              }}
              onCancel={() => handleCloseForm()}
            />
          )}
          {cardType === PaymentMethodCardType.Card && (
            <>
              <PaymentMethodForm
                existingPayment={selectedPayment as IPaymentProfile | undefined}
                onCancel={() => handleCloseForm(() => globalThis.Spreedly.removeHandlers())}
                onSubmit={handleSavedPayment}
                paymentProfiles={paymentProfiles}
              />
              <PrivacyPolicyDisclaimer $top="30px" />
            </>
          )}
        </>
      )}

      {paymentMethodMode === PaymentMethodMode.Change && (
        <Box display="flex" flexDirection="column" alignItems="stretch" gap={16}>
          <div>
            {!!hasDirectBill && !isDirectBillBlocked && (
              <DirectBillItem onPaymentSelect={handlePaymentSelect} />
            )}
            {!!paymentProfiles &&
              paymentProfiles?.map((paymentItem) => {
                return (
                  <PaymentItem
                    key={paymentItem.id}
                    onPaymentSelect={handlePaymentSelect}
                    onEditPayment={() => {
                      setFieldValue("selectedPaymentId", paymentItem.id);
                      setCardType(PaymentMethodCardType.Card);
                      setPaymentMethodMode(PaymentMethodMode.Edit);
                    }}
                    payment={paymentItem}
                  />
                );
              })}
          </div>
          <>
            {(!directBillAccess || !!isDirectBillOptional) && (
              <Styled.AddPaymentButton type="link" onClick={handleAddPayment}>
                <Styled.PlusIcon icon={["fal", "plus"]} />
                Add Payment Method
              </Styled.AddPaymentButton>
            )}
          </>
        </Box>
      )}
    </Box>
  );
};
