import { tokenKey } from "@hotel-engine/constants";
import { User } from "@hotel-engine/services";
import storage from "@hotel-engine/storage";
import type { IUser } from "@hotel-engine/types/user";

import {
  isAddingNewTokenToExistingJson,
  isUpdatingAccountTypeFromUnknown,
  isUpdatingAccountTypeValueInJSON,
  isUpdatingTokenFormatToJSON,
} from "./helpers";
import type { AccessTokenStored } from "./helpers";

export const setToken = (token?: string, impersonatingUser?: boolean, setForCurrent?: boolean) => {
  const currentToken = storage.getSecureItem(tokenKey);
  const currentUser = User.current();
  const expirationTime = 3650; // 3650 = 10 yrs in days
  const bc = new BroadcastChannel("impersonate_user");

  bc.onmessage = () => {
    globalThis.location.reload();
  };

  if (!currentToken || impersonatingUser) {
    if (impersonatingUser) {
      bc.postMessage({ impersonatingUser: true });
    } else {
      bc.close();
    }
    const tokens: AccessTokenStored = {
      unknown: token,
      latestAccountType: "unknown",
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  } else if (isAddingNewTokenToExistingJson(currentToken, token, currentUser?.accountType)) {
    const parsedToken: AccessTokenStored = JSON.parse(currentToken as string);
    const newAccountType = setForCurrent
      ? currentUser.accountType
      : currentUser.accountType === "business"
        ? "personal"
        : "business";

    const tokens: AccessTokenStored = {
      ...parsedToken,
      [newAccountType]: token,
      latestAccountType: newAccountType,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  } else if (isUpdatingTokenFormatToJSON(currentToken, currentUser?.accountType)) {
    const tokens: AccessTokenStored = {
      [currentUser.accountType]: currentToken,
      latestAccountType: currentUser.accountType,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  } else if (isUpdatingAccountTypeFromUnknown(currentToken, token)) {
    const parsedToken: AccessTokenStored = JSON.parse(currentToken as string);
    const tokens: AccessTokenStored = {
      ...parsedToken,
      [currentUser.accountType]: parsedToken.unknown,
      latestAccountType: currentUser.accountType,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  } else if (isUpdatingAccountTypeValueInJSON(currentToken, token)) {
    const parsedToken: AccessTokenStored = JSON.parse(currentToken as string);
    const tokens: AccessTokenStored = {
      ...parsedToken,
      latestAccountType: currentUser.accountType,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  }
};

export const setPersonalToken = (token: string, userAccountType: IUser["accountType"]) => {
  const currentToken = storage.getSecureItem(tokenKey);
  const expirationTime = 3650; // 3650 = 10 yrs in days

  if (isAddingNewTokenToExistingJson(currentToken, token, userAccountType)) {
    const parsedToken: AccessTokenStored = JSON.parse(currentToken as string);
    const tokens: AccessTokenStored = {
      ...parsedToken,
      personal: token,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  } else if (isUpdatingTokenFormatToJSON(currentToken, userAccountType)) {
    const tokens: AccessTokenStored = {
      [userAccountType]: currentToken,
      personal: token,
      latestAccountType: userAccountType,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  }
};

export const cleanAccessToken = (tokenToKeep: "business" | "personal" | "unknown" | undefined) => {
  const currentToken = storage.getSecureItem(tokenKey);

  if (currentToken && tokenToKeep) {
    const expirationTime = 3650; // 3650 = 10 yrs in days
    const parsedToken: AccessTokenStored = JSON.parse(currentToken);
    const tokens: AccessTokenStored = {
      [tokenToKeep]: parsedToken[tokenToKeep],
      latestAccountType: tokenToKeep,
    };
    storage.setSecureItem(tokenKey, JSON.stringify(tokens), {
      expires: expirationTime,
      samesite: "None" as "",
      secure: true,
    });
  }
};
