/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/naming-convention, @typescript-eslint/no-namespace, @typescript-eslint/no-empty-interface */
import moment from "moment";

import type { TokensSchema } from "@hotelengine/atlas-web";

import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import {
  BILLING,
  COMPANY_SETTINGS,
  DASHBOARD,
  GROUPS,
  MEMBERS,
  TRENDS,
  TRIPS,
  SUPPORT_CENTER,
  routes,
  TRAVEL_POLICIES,
} from "@hotel-engine/constants";
import { Auth } from "@hotel-engine/services";
import {
  User,
  type IRewardsProfile,
  type IRewardsProfileCurrentTier,
  type IRewardsProfileNextTier,
  type IUser,
} from "@hotel-engine/types/user";
import { Rewards } from "@hotel-engine/types/rewards";

import { stringify } from "qs";

import type { ActionCreator, AnyAction } from "redux";

import type { ILinks } from "./constants";
import { LinkValues, roleLinks } from "./constants";
import { hasDirectBillEnabled } from "@hotel-engine/utilities";
import type { DefaultTheme } from "styled-components";
import { Fn } from "@hotel-engine/data";

type ThemeColor = keyof TokensSchema["colors"];
const themeColor = <T extends ThemeColor>(token: T): T => token;

export const getCurrentState: (pathname: string) => ILinks["id"] | null = (pathname) => {
  switch (true) {
    case pathname === "/":
      return DASHBOARD;
    case Boolean(pathname.includes(TRIPS)):
      return TRIPS;
    case Boolean(
      pathname === routes.billing ||
        pathname === routes.balance ||
        pathname === routes.paymentMethods ||
        pathname === routes.creditLineIncrease ||
        pathname === routes.creditCardTransactions ||
        pathname === routes.paymentHistory ||
        pathname === routes.paymentDetails
    ):
      return BILLING;
    case Boolean(pathname.includes(TRENDS)):
      return TRENDS;
    case Boolean(pathname.includes(TRAVEL_POLICIES)):
      return TRAVEL_POLICIES;
    case Boolean(pathname.includes(COMPANY_SETTINGS)):
      return COMPANY_SETTINGS;
    case Boolean(pathname.includes(MEMBERS)):
      return MEMBERS;
    case Boolean(pathname.includes(GROUPS)):
      return GROUPS;
    case Boolean(pathname.includes(SUPPORT_CENTER)):
      return SUPPORT_CENTER;
    default:
      return null;
  }
};

export const getNavLinks: (user: IUser) => ILinks[] = (user) => {
  const findLinkValues = (link: ILinks["id"]) => LinkValues.find((element) => element.id === link);

  return roleLinks[user.role].reduce((links: ILinks[], link) => {
    const originalLinkVal = findLinkValues(link);
    if (!originalLinkVal) return links;

    // Deep clone to avoid mutating the original LinkValues object
    const linkVal = { ...originalLinkVal };
    const hasDBEnabled = hasDirectBillEnabled(user);

    // allows Admin to see `Billing` tab link in the nav bar when Direct Bill is not enabled
    if (!hasDBEnabled && linkVal.id === BILLING) {
      // change route for BILLING to creditCardTransactions
      linkVal.route = routes.creditCardTransactions;
    }

    // with introduction of more subnav menu we need to filter out the subMenuItems based on the role
    if (linkVal.type === "menu-trigger") {
      linkVal.subMenuItems = linkVal.subMenuItems.filter((item) => {
        if (user.role === "coordinator" && !user.hasReportingAccess && item.id === "trends") {
          return false;
        }
        return roleLinks[user.role].find((id) => id === item.id);
      });
    }

    links.push(linkVal);
    return links;
  }, []);
};

export const handleSignOut = async (
  navigate: NavigateFunction,
  signOut: ActionCreator<AnyAction>
) => {
  await Auth.signOut("helpers.handleSignOut");
  signOut(); // Remove user from Auth redux state
  navigate(routes.login);
};

export const setRewardsBarWidth = (
  type: "spend" | "bookings",
  nextTier: IRewardsProfileNextTier | null,
  spend: number | string,
  bookings: number | string
) => {
  const min = 0;
  const max = 100;

  const bookingCountUnlock = nextTier?.bookingCountUnlock ? nextTier.bookingCountUnlock : 0;
  const spendUnlock = nextTier?.spendUnlock ? Number(nextTier.spendUnlock) : 0;

  let width = 0;

  if (type === "spend") {
    width = +spend / spendUnlock;
  }
  if (type === "bookings") {
    width = +bookings / bookingCountUnlock;
  }

  width = width * 100;

  if (width < 0) {
    width = min;
  } else if (width > 100) {
    width = max;
  }

  return Math.ceil(width);
};

/**
 * Remove a given key from a given object
 * @param params object to modify
 * @param toRemove key to remove from params
 * @returns a new object withou the specific key provided in toRemove
 */
export const removeParameter = (params: { [key: string]: string }, toRemove: string) =>
  Object.keys(params).reduce(
    (acc, currentKey) =>
      currentKey === toRemove ? acc : { ...acc, [currentKey]: params[currentKey] },
    {}
  );

export const buildQueryString: (params: { [key: string]: string }) => string = stringify;

export const getUserRoleEnabledFeatures = (user: IUser) => {
  const personalTravelEnabled = user.business.personalTravelEnabled;
  const userIsActuallyPersonalTraveler = !!(user.businessTravelUserId && user.role === "user");
  const canCreatePersonalAccount =
    user.accountType === "business" &&
    !user.business.isPerkAccount &&
    !user.personalTravelUserId &&
    user.business.personalTravelEnabled;
  const canSwitchToPersonal = !!(
    user.accountType === "business" &&
    !user.business.isPerkAccount &&
    user.personalTravelUserId &&
    user.business.personalTravelEnabled
  );
  const canSwitchToBusiness = !!(
    user.accountType === "personal" &&
    user.businessTravelUserId &&
    user.business.personalTravelEnabled
  );
  const notViewOnlyTraveler = !User.isViewOnlyTraveler(user);
  const shouldShowReferAFriend =
    user.business.referFriendsEnabled &&
    (user.business.isPerkAccount || user.accountType === "personal") &&
    notViewOnlyTraveler;
  const shouldShowTravelCredits =
    user.business.travelCreditsAllowed &&
    (!user.business.hasPooledTravelCredits || user.role !== "user") &&
    notViewOnlyTraveler;

  return {
    personalTravelEnabled,
    userIsActuallyPersonalTraveler,
    canCreatePersonalAccount,
    canSwitchToPersonal,
    canSwitchToBusiness,
    shouldShowReferAFriend,
    shouldShowMyProperties: notViewOnlyTraveler,
    shouldShowTravelCredits,
  };
};

export type CurrentTierColor = typeof CurrentTierColor;
export const CurrentTierColor = {
  Disabled: themeColor("neutralN300"),
  Member: themeColor("neutralN300"),
  Silver: themeColor("neutralN300"),
  Gold: themeColor("accentYellowLight"),
  Platinum: themeColor("accentBlueLight"),
} as const satisfies Record<Rewards.Tier, string>;

export const lookupTierColor = (props: { theme: DefaultTheme; $rewardsTier: string }): string =>
  props.$rewardsTier in CurrentTierColor
    ? props.theme.colors[CurrentTierColor[props.$rewardsTier]]
    : props.theme.colors[CurrentTierColor.Disabled];

export const getAdditionalInfoCopy = (
  shouldShowRewards: boolean,
  rewardsProfile: NormalizedRewards.Any,
  confirmedAt: string | null
) => {
  if (
    !shouldShowRewards ||
    rewardsProfile.tier === Rewards.Tier.Disabled ||
    rewardsProfile.tier === Rewards.Tier.Member
  ) {
    return !!confirmedAt ? `Member since ${moment(confirmedAt).format("YYYY")}` : null;
  } else
    return `${rewardsProfile.tier} through ${moment(rewardsProfile.tierStartDate).format(
      "MMM D[,] YYYY"
    )}`;
};

export type RewardsSpendUnlock = readonly [
  current: number & IRewardsProfile["spend"],
  unlock: number & NonNullable<IRewardsProfile["nextTier"]>["spendUnlock"],
];

export type RewardsBookingsUnlock = readonly [
  current: number & IRewardsProfile["bookings"],
  unlock: number & (IRewardsProfile["nextTier"] & {})["bookingCountUnlock"],
];

export namespace NormalizedRewards {
  export interface Base {
    points: IRewardsProfile["points"];
    spend: IRewardsProfile["spend"];
    currentTier: IRewardsProfileCurrentTier;
    tierStartDate: NonNullable<IRewardsProfile["nextTier"]>["startDate"];
  }

  export interface Disabled {
    tier: Rewards.Tier.Disabled;
  }

  export interface Member extends NormalizedRewards.Base {
    tier: Rewards.Tier.Member;
    nextTier: Rewards.NextTier[Rewards.Tier.Member];
    spendUnlock: RewardsSpendUnlock;
    bookingsUnlock: RewardsBookingsUnlock;
  }

  export interface Silver extends NormalizedRewards.Base {
    tier: Rewards.Tier.Silver;
    nextTier: Rewards.NextTier[Rewards.Tier.Silver];
    spendUnlock: RewardsSpendUnlock;
    bookingsUnlock: RewardsBookingsUnlock;
  }

  export interface Gold extends NormalizedRewards.Base {
    tier: Rewards.Tier.Gold;
    nextTier: Rewards.NextTier[Rewards.Tier.Gold];
    spendUnlock: RewardsSpendUnlock;
    bookingsUnlock: RewardsBookingsUnlock;
  }

  export interface Platinum extends NormalizedRewards.Base {
    tier: Rewards.Tier.Platinum;
    nextTier?: never;
    spend: IRewardsProfile["spend"];
    bookings: IRewardsProfile["bookings"];
  }

  export type WithNextTier =
    | NormalizedRewards.Gold
    | NormalizedRewards.Silver
    | NormalizedRewards.Member;

  export type Enabled = NormalizedRewards.WithNextTier | NormalizedRewards.Platinum;

  export type Any = NormalizedRewards.Disabled | NormalizedRewards.Enabled;

  export const is = {
    Disabled: (profile: NormalizedRewards.Any): profile is NormalizedRewards.Disabled =>
      profile.tier === Rewards.Tier.Disabled,
    Member: (profile: NormalizedRewards.Any): profile is NormalizedRewards.Member =>
      profile.tier === Rewards.Tier.Member,
    Silver: (profile: NormalizedRewards.Any): profile is NormalizedRewards.Silver =>
      profile.tier === Rewards.Tier.Silver,
    Gold: (profile: NormalizedRewards.Any): profile is NormalizedRewards.Gold =>
      profile.tier === Rewards.Tier.Gold,
    Platinum: (profile: NormalizedRewards.Any): profile is NormalizedRewards.Platinum =>
      profile.tier === Rewards.Tier.Platinum,
  };
}

/**
 * ### {@link decodeRewardsProfile `decodeRewardsProfile`}
 *
 * Decodes an API {@link IRewardsProfile `IRewardsProfile`} into a
 * {@link Rewards.Profile `Rewards.Profile`}.
 */
export const decodeRewardsProfile = (profile: IRewardsProfile | null): Rewards.Profile =>
  profile === null
    ? { _tag: Rewards.Tier.Disabled }
    : { ...profile, _tag: profile.currentTier.name as Rewards.Tier };

interface ParseIntOptions {
  onNaN?: number;
  radix?: number;
}
interface ParseIntConfig extends Required<ParseIntOptions> {}

const parseIntConfig = (options?: ParseIntOptions): ParseIntConfig => ({
  onNaN: options?.onNaN ?? 0,
  radix: options?.radix ?? 10,
});

const parseInt = (stringNumeric: string, options?: ParseIntOptions) => {
  const config = parseIntConfig(options);
  const parsed = Number.parseInt(stringNumeric, config.radix);
  return Number.isFinite(parsed) ? parsed : config.onNaN;
};

/**
 * ### {@link normalizeRewardsProfile `normalizeRewardsProfile`}
 *
 * {@link normalizeRewardsProfile `normalizeRewardsProfile`} converts an
 * API {@link IRewardsProfile} directly into the format that the Header
 * component needs to work with.
 */
export const normalizeRewardsProfile = (profile: IRewardsProfile | null): NormalizedRewards.Any => {
  if (profile === null) return { tier: Rewards.Tier.Disabled };
  else if (profile.currentTier.name === Rewards.Tier.Platinum)
    return {
      tier: profile.currentTier.name,
      currentTier: profile.currentTier,
      points: profile.points,
      bookings: profile.bookings,
      spend: profile.spend,
      tierStartDate: profile.currentTier.startDate,
    };
  else if (
    profile.currentTier.name === Rewards.Tier.Member ||
    profile.currentTier.name === Rewards.Tier.Silver ||
    profile.currentTier.name === Rewards.Tier.Gold
  )
    return {
      tier: profile.currentTier.name,
      currentTier: profile.currentTier,
      points: profile.points,
      spend: profile.spend,
      nextTier: Rewards.NextTier[profile.currentTier.name as never],
      tierStartDate: profile.joinDate,
      spendUnlock: [
        parseInt(`${profile.spend}`),
        parseInt(`${(profile.nextTier ?? { spendUnlock: 0 }).spendUnlock}`),
      ],
      bookingsUnlock: [
        parseInt(`${profile.bookings}`),
        parseInt(`${(profile.nextTier ?? { bookingCountUnlock: 0 }).bookingCountUnlock}`),
      ],
    };
  else return {} as never;
};

/**
 * ### {@link formatPoints `formatPoints`}
 *
 * Encodes a user's reward points into a lossy, but more compact format.
 *
 * @example
 *  formatPoints(5.05)   // => "5"
 *  formatPoints(5_000)  // => "5,000"
 *  formatPoints(50_000) // => "50K"
 */
const BILLIONS_LEN = -11;
const MILLIONS_LEN = -7;
const THOUSANDS_LEN = -3;
export const formatPoints: (points: number) => string = Fn.flow(
  Math.floor,
  Intl.NumberFormat().format,
  (s: string) => {
    const digitsOnly = s.replace(",", "");
    const digitsLen = digitsOnly.length;

    if (digitsLen > 10) {
      return `${digitsOnly.slice(0, BILLIONS_LEN)}B`;
    }

    if (digitsLen > 7) {
      return `${digitsOnly.slice(0, MILLIONS_LEN)}M`;
    }

    if (digitsLen > 4) {
      return `${digitsOnly.slice(0, THOUSANDS_LEN)}K`;
    }

    return s;
  }
);
