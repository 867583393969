import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { IUserPrefsState } from "@hotel-engine/types/user";

export const initialState: IUserPrefsState = {
  dashboardCalloutDismissed: false,
  dashboardTabPreference: "favorites",
  favoriteCalloutDismissed: false,
  guestCount: 2,
  lastPaymentId: null,
  roomCount: 1,
  singleRoomGuestCount: 2,
  showEngines: false,
  showRatelessProperties: false,
  showSearchRadiusCircle: false,
  showSearchMapTools: false,
};

export const toggleSearchRadiusCircle = (state = initialState, { showSearchRadiusCircle }) => ({
  ...state,
  showSearchRadiusCircle,
});

export const userPrefsSlice = createSlice({
  name: "UserPrefs",
  initialState,
  reducers: {
    clearUserPrefs: () => initialState,
    dismissDashboardCallout: (state) => ({
      ...state,
      dashboardCalloutDismissed: true,
    }),
    dismissFavoriteCallout: (state) => ({
      ...state,
      favoriteCalloutDismissed: true,
    }),
    setDashboardTabPref: (
      state,
      action: PayloadAction<IUserPrefsState["dashboardTabPreference"]>
    ) => ({
      ...state,
      dashboardTabPreference: action.payload,
    }),
    setGuestCount: (state, action: PayloadAction<number>) => ({
      ...state,
      guestCount: action.payload,
    }),
    setLastPaymentId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      lastPaymentId: action.payload,
    }),
    setRoomCount: (state, action: PayloadAction<number>) => ({
      ...state,
      roomCount: action.payload,
    }),
    setSingleRoomGuestCount: (state, action: PayloadAction<number>) => ({
      ...state,
      singleRoomGuestCount: action.payload,
    }),
    toggleEnginePreferences: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showEngines: action.payload,
    }),
    toggleRatelessProperties: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showRatelessProperties: action.payload,
    }),
    toggleSearchRadiusCircle: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showSearchRadiusCircle: action.payload,
    }),
    toggleSearchMapTools: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showSearchMapTools: action.payload,
    }),
  },
});

export const UserPrefsActions = userPrefsSlice.actions;

export default userPrefsSlice.reducer;
