import styled, { css } from "styled-components";
import airlineLogos from "@hotel-engine/assets/flights/airline_logos.json";
import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";

const airlineLogoIATAs = new Set(airlineLogos);
const fallbackIcon = "/assets/flights/placeholder-airline.svg";
const multiAirlineIcon = "/assets/flights/placeholder-airlines.svg";

const VectorImage = styled.img<{ $size: number; $rounded: boolean }>`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  object-fit: contain;
  ${({ $rounded, theme }) => $rounded && css`border-radius: ${theme.borderRadius.xs}`};
`;

interface IAirlineLogoProps {
  /** The two-character IATA code for the airline, or an array of airline IATA codes. */
  iata: string | string[];
  /** An optional width and height value for logo size (defaults to 16). */
  size?: number;
  rounded?: boolean;
}

export function AirlineLogo({ iata, size = 16, rounded = false }: IAirlineLogoProps) {
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();

  // Allow the component to take a single IATA string, or an array of them.
  const iataCodes = Array.isArray(iata) ? iata : iata ? [iata] : [];

  // Don't even check if we have logos if there are multiple airlines.
  if (iataCodes.length > 1) {
    return (
      <VectorImage src={multiAirlineIcon} alt="Multiple Airlines" $rounded={rounded} $size={size} />
    );
  }

  // Check to make sure we actually have a logo for this airline.
  const logosToShow = iataCodes.filter((code) => airlineLogoIATAs.has(code));

  if (logosToShow.length == 0) {
    return (
      <VectorImage
        src={fallbackIcon}
        alt="Fallback Icon"
        $rounded={rounded}
        $size={size}
        className="airline-log"
      />
    );
  } else {
    const airlineIATA = logosToShow[0];
    const airlineName = getAirlineNameFromIataAirlineCode(airlineIATA);
    return (
      <VectorImage
        src={`/assets/flights/airline-logos/${airlineIATA}.svg`}
        alt={`Logo for ${airlineName}`}
        $size={size}
        $rounded={rounded}
        className="airline-log"
      />
    );
  }
}

// Examples of different test scenarios for the AirlineLogo component.
export function AirlinesLogoExamples() {
  const singleCode = "NK"; // Spirit
  const multipleCodes = ["DL", "UA"]; // Delta and United
  const noMatchCode = ["XX"]; // Non-existent code

  return (
    <div>
      <h1>Airline Logo Variations</h1>
      <ul>
        <li>
          Single Airline: <AirlineLogo iata={singleCode} />
        </li>
        <li>
          Multiple Airlines: <AirlineLogo iata={multipleCodes} />
        </li>
        <li>
          Missing Logo: <AirlineLogo iata={noMatchCode} />
        </li>
      </ul>
    </div>
  );
}
