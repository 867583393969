import { lazy } from "react";

// This static import is actually a Switch containing additional (dynamic) routes
export { RatePartnering } from "./pages/RatePartnering";

// ========================================================
// ===== Chunk 1: What's necessary for login page =========
// ========================================================

export const Login = lazy(() => import(/* webpackChunkName: "Login" */ "./pages/Login/Login"));

// ========================================================
// =========== Chunk 2: Dashboard and Search ==============
// ========================================================

export const Dashboard = lazy(
  () => import(/* webpackChunkName: "FindResults" */ "./pages/Dashboard")
);

export const SearchResults = lazy(
  () => import(/* webpackChunkName: "FindResults" */ "./pages/SearchResults")
);

export const FlightsSearch = lazy(() => import("./pages/Flights/Search"));

export const TestPage = lazy(() => import("./pages/Flights/TestPage"));

// ========================================================
// ======= Chunk 3: Everything else, all other pages ======
// ========================================================

export const AutopayCancel = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/AutopayCancel")
);

export const AutopayFAQ = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/AutopayFAQ"));

export const AutopaySetup = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/AutopaySetup")
);

export const Balance = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Balance"));

export const Billing = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Billing"));

export const CreditCardTransactions = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/CreditCardTransactions")
);

export const CreditLineIncrease = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/CreditLineIncrease")
);

export const BillingIssue = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/BillingIssue")
);

export const Checkout = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Checkout"));

export const FlightsCheckout = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Checkout/Flights")
);

export const FlightsSeatmapPortal = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Checkout/FlightSeatmapPortal")
);

export const CheckoutVerification = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/CheckoutVerification")
);

export const CompanySettings = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/CompanySettings")
);

export const DirectBillFAQ = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/DirectBillFAQ")
);

export const ExtendTrips = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ExtendTrips")
);

export const PersonalAccountLanding = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PersonalAccountLanding")
);

export const FlexFAQ = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/FlexFAQ"));

export const FlexPassFAQ = lazy(() => import(/* webpackChunkName: "Other" */ "pages/FlexPassFAQ"));

export const Groups = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Groups"));

export const IncidentalsFAQ = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/IncidentalsFAQ")
);

export const Itinerary = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Itinerary"));

export const JoinPageLayout = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Join"));

export const JoinFlow = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/JoinFlow")
);

export const JoinConfirm = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Join/components/Confirm")
);

export const JoinReferFriendsFlow = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/ReferFriendsFlow")
);

export const JoinPartnersFlow = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Join/definitions/PartnersFlow")
);

export const JoinFinish = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Join/components/Finish")
);

export const GroupsJoin = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Groups/pages/GroupsJoin")
);

// import { Confirm } from "./components/Confirm";
// import { ReferFriendsFlow } from "./definitions";
// import { PartnersFlow } from "./definitions/PartnersFlow";

export const MemberDetails = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/MemberDetails")
);

export const Members = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Members"));

export const ModifyReservation = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ModifyReservation")
);

export const MyProperties = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/MyProperties")
);

export const NewSSOConfigConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "Other" */
      "./pages/CompanySettings/components/AuthStrategyForm/components/Confirmation"
    )
);

export const Orders = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Orders"));

export const PaymentDetails = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PaymentDetails")
);

export const PaymentHistory = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PaymentHistory")
);

export const PaymentMethods = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PaymentMethods")
);

export const PaymentReview = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PaymentReview")
);

export const PersonalTravelConfirm = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravelConfirm")
);

export const PersonalTravelUnconfirmed = lazy(
  () =>
    import(/* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravelUnconfirmed.tsx")
);

export const PersonalTravel2FV = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PersonalTravel/PersonalTravel2FV")
);

export const Property = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Property"));

export const PropertyReview = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/PropertyReview")
);

export const ReferFriends = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ReferFriends")
);

export const ReferFriendsProgramDetails = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ReferFriendsProgramDetails")
);

export const Rewards = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Rewards"));

export const RewardsEnrollment = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/RewardsEnrollment")
);

export const RewardsFaq = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/RewardsFaq"));

export const RewardsTerms = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/RewardsTerms")
);

export const Settings = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Settings"));

export const CarsCheckout = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/Checkout/Cars")
);

export const ShareOptions = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ShareOptions/ShareOptionsWrapper")
);

export const SignUp = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/SignUp"));

export const Statement = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Statement"));

export const SupportCenter = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/SupportCenter")
);

export const TravelCredits = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/TravelCredits")
);

export const TravelPoliciesLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Other" */
      "./pages/CompanySettings/components/TravelPolicies/TravelPoliciesLandingPage"
    )
);

export const TravelPolicyFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Other" */ "./pages/CompanySettings/components/TravelPolicies/TravelPolicyFormPage"
    )
);

export const TrendsLegacy = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/TrendsLegacy")
);
export const Trends = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Trends"));

export const Trips = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/Trips"));
