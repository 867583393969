import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constants";
import {
  updateSearchMapClusteringProperties,
  updateSearchMapControls,
  updateSearchMapCustomControls,
  updateSearchMapDatasetProperties,
  updateSearchMapOptions,
} from "./reducers";

export const searchMapSlice = createSlice({
  name: "SearchMap",
  initialState,
  reducers: {
    updateSearchMapDatasetProperties,
    updateSearchMapClusteringProperties,
    updateSearchMapOptions,
    updateSearchMapControls,
    updateSearchMapCustomControls,
    resetSearchMapProperties: () => initialState,
  },
});

export const searchMapActions = searchMapSlice.actions;

export default searchMapSlice.reducer;
