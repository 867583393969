import InputField, { type IInputFieldProps } from "@hotel-engine/common/FormikFields/InputField";

type ExpDateInputProps = IInputFieldProps;

export const ExpDateInput = (props: ExpDateInputProps) => {
  const {
    field: { onChange, value, ...fieldRest },
    ...rest
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const displayValue = e.target.value.replace(/[^0-9/]/g, "").replace(/(\d{2})(\d)/, "$1/$2");
    e.target.value = displayValue;
    onChange?.(e);
  };

  return (
    <InputField
      name="expDate"
      label="Expiration date"
      placeholder="MM/YY"
      maxLength={5}
      help="Required if displayed on card"
      field={{ ...fieldRest, value, onChange: handleChange }}
      {...rest}
    />
  );
};
