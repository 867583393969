import type { IAuthStrategies } from "@hotel-engine/types/authStrategy";

import { FormControl, Select, SelectItem } from "@hotelengine/atlas-web";

interface IBusinessSelectProps {
  authStrategies: IAuthStrategies;
  handleBusinessSelect: (strategyIndex: string) => void;
}

const BusinessSelect = ({ authStrategies, handleBusinessSelect }: IBusinessSelectProps) => (
  <FormControl label="Which business do you work with?" style={{ width: "100%", marginBottom: 24 }}>
    <Select
      id="business-select"
      data-testid="business-select"
      onValueChange={handleBusinessSelect}
      placeholder="Select business"
      style={{ width: "100%" }}
    >
      {authStrategies.all.map((strategy, _index) => (
        <SelectItem value={String(_index)} key={`${strategy.displayName}-${strategy.url}`}>
          {strategy.displayName}
        </SelectItem>
      ))}
    </Select>
  </FormControl>
);

export default BusinessSelect;
