import { Icon } from "@hotelengine/atlas-web";

import config from "config";

import { type PaymentMethodCardType } from "./types";
import { type PaymentOptionProps } from "./components/PaymentOption";

export const PaymentMethodOptions: Record<
  PaymentMethodCardType,
  Omit<PaymentOptionProps, "onClick">
> = {
  card: {
    icon: <Icon name="credit-card" size="lg" />,
    label: "Add credit or debit card",
  },
  wex: {
    icon: <img src={`${config.cdnHost}/assets/creditcards/wex.png`} alt="Wex Fleet card" />,
    label: "Add Wex Fleet card",
  },
};
